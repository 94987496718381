
.searchBar .form-control {
 
    background-color: #E7E9EB;
    
}


.searchBar ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #212529;
    background-color: #E7E9EB;
    opacity: 1; /* Firefox */
  }
  
  .searchBar :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #212529;
    background-color: #E7E9EB;
  }
  
  .searchBar ::-ms-input-placeholder { /* Microsoft Edge */
    color: #212529;
    background-color: #E7E9EB;
  }


  .searchBar{
    
    float: left;
    margin-right: 2%;
  }
  .searchBar .search-control{
 
    float: left;
  }
  
  .datePickerContainer{
    width: 20%;
    float:left;
    padding: 0.375rem 0.75rem;
  }



  .datePickerContainer input{
 
   border: 1px dotted;
    border-radius: 55px;
    background-color: #E7E9EB;
    text-align: center;
     
  }

  .searchText{
    font-size: .7em;
    color: #797979;
    text-align: center;
}
